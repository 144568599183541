import {EItemType, ESubscriptionType} from './models';
import {environment} from '../../environments/environment';

export enum ECookieTokens {
  USER = 'adCoreUser',
  LOGIN_ATTEMPT = 'adCoreLoginAttempt',
  TOKEN = 'adCoreToken',
  REFRESH_TOKEN = 'adCoreRefreshToken',
  ADMIN_TOKEN = 'adCoreAdminToken',
  ADVERTISER_TOKEN = 'adCoreAdvertiserToken',
  CHANNEL_TOKEN = 'adCoreChannelToken',
  MCC_TOKEN = 'adCoreMccToken',
  ACCOUNT_TOKEN = 'adCoreAccountToken',
  CAMPAIGN_TOKEN = 'adCoreCampaignToken',
  FEED_TOKEN = 'ADCORE_COOK_FEED_SELECTED',
  ADCORE_COOK_FEED_VIEW_SELECTED = 'ADCORE_COOK_FEED_VIEW_SELECTED',
  ADCORE_TIME_PERIOD_SELECTED = 'ADCORE_TIME_PERIOD_SELECTED',
  TIME_PERIOD_TOKEN = 'adCoreTimePeriodToken',
  USER_NAME = 'adCoreUserNameToken',
  SELECTED_PAGES = 'adCoreSelectedPagesToken',
  FEED_WORKING_MODE = 'feeditorWorkingMode',
  JOIN_PARAMS = 'adCoreJoinParams',
  ADMIN_2FA = 'adCoreAdmin2faStatus',
}


export const GLOBAL_CHANNELS_ENUM = {
  '562deb161c2b61600bc193c1': 'google',
  '5d5441918eaf6822b2d7e25e': 'bing'
};

export const LIST_VISIBLE_MAX = 4;
export const SUPER_ADMIN_REGEX = /keren@adcore.com|noam@adcore.com|omri@adcore.com|vadim@adcore.com|shiying@adcore.com|shir@adcore.com|alina@adcore.com|litalvaserman1@gmail.com|andy@adcore.com|ofri@amphy.com|ladfah@adcore.com/i;
export const POLLING_INTERVAL = 10000;
export const MAX_AMOUNT_OF_LOGIN_ATTEMPTS = 5;
export const MAX_FILE_SIZE_IN_BYTES = 10485760;
export const MAX_ROWS_AMOUNT = 10000;
export const MAX_ENRICH_ROWS_AMOUNT = 30000;
export const MAX_SAMPLE_ROWS_AMOUNT = 50000;
export const ENTERPRISE_MAX_ENRICH_ROWS_AMOUNT = 50000;
export const HELP_QUERY_URL = 'https://help.adcore.com/kb-search-results?term=';
export const PRIVACY_POLICY_URL = 'https://www.adcore.com/privacy-policy/';
export const DEMO_ADVERTISER_ID = '5e3c39a96535423520de118c';
export const AUTO_SAVE_INTERVAL_IN_MINUTES = 2;
export const REPORT_USER_ON_PAGE_IN_MINUTES = 5;
export const COOKIE_HOURS_EXPIRE = 30 * 24;
export const COOKIE_DAYS_EXPIRE = 2;
export const MAX_RECENT_ITEMS = 5;
export const MAX_PAGE_SIZE = 10;
export const MAX_ENRICH_DATA_FEEDS = 5;
export const APPS_WITH_ACCOUNT = ['semdoctor', 'views', 'hurricane', 'alerter', 'mediablast'];
export const APPS_WITH_FEED = ['feeditor', 'effortless', 'feeditor-plus'];
export const MODULES_OPEN_IN_TRIAL = ['feeditor', 'semdoctor', 'adcore', 'alerter', 'feeditor_plus'];
export const APPS_WITHOUT_VERSIONS = ['semdoctor', 'alerter', 'hurricane', 'mediablast'];
export const TRIAL_DAYS_LIMIT = 14;
export const defaultUserProfileImage =  `${environment.apiUrl}/public/image/get/user-default-image.png`;
export const EMAIL_REGEXP = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,10})$/i;
export const IGNORE_DOWNLOAD_URL_PART = '/api/v1/shopify/products/directAccess';
export const DEFAULT_MODULES_LIST = [{
    module: 'adcore',
    active: false,
    type: 'starter',
    pages: ['campaign']
  }, {
    module: 'feeditor',
    active: false,
    type: 'starter',
    pages: ['feedit']
  }, {
    module: 'semdoctor',
    active: false,
    type: 'starter',
    pages: ['semdoctor', 'report', 'fixit']
  }, {
  module: 'alerter',
  active: false,
  type: 'starter',
  pages: []
},{
    module: 'effortless_feed',
    active: false,
    type: 'starter',
    pages: ['effortlessfeed']
  }];

export const addYear = (years: number = 1): any => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + years);
  return date;
};

// @ts-ignore
export const DEFAULT_PLAN_SETTINGS = {
  plan: 'all',
  feedSize: 'FEEDITOR_PLUS',
  paymentFrequency: 'yearly',
  totalLicenses: 1,
  availableLicenses: 0,
  tasks: 1000,
  freeTasks: 100,
  tasksAddon: 0,
  isLicenseOwner: 'no',
  subscriptionEnd: addYear().getTime() / 1000,
  subscriptionStart: (new Date()).getTime() / 1000,
  subscriptionType: ESubscriptionType.INITIAL
};

export const appNamePatch = (appName?: string | null): string => {
  if (appName?.toLowerCase() === 'views') {
    return 'adcore';
  }
  if (appName?.toLowerCase() === 'effortless') {
    return 'effortless_feed';
  }
  if (appName?.toLowerCase() === 'feeditor-plus') {
    return 'feeditor_plus';
  }
  if (appName?.toLowerCase() === 'media-blast') {
    return 'mediablast';
  }
  return appName || '';
};

export const moduleNamePatch = (module?: string | null): string => {
  if (module?.toLowerCase() === 'adcore') {
    return 'views';
  }
  return (module || '').split('/')[0];
};


export const moduleLabelPatch = (module?: string | null): string => {
  if (module?.toLowerCase() === 'semdoctor') {
    return 'sem_doctor';
  }
  if (module?.toLowerCase() === 'effortless') {
    return 'effortless_marketing';
  }
  if (['settings','my-cloud'].includes(module?.toLowerCase() || '')) {
    return 'marketing_cloud';
  }
  if (module?.toLowerCase() === 'feeditor-plus') {
    return 'feeditor';
  }
  return module || '';
};

export enum ELocalStorageTokens {
  RECENT_ADVERTISERS = 'adCoreNG_RecentAdvertisers',
  RECENT_FEEDS = 'adCoreNG_RecentFeeds',
  RECENT_PLUS_FEEDS = 'adCoreNG_RecentPlusFeeds',
  RECENT_ACCOUNTS = 'adCoreNG_RecentAccounts',
  RECENT_VERSIONS = 'adCoreNG_RecentVersions',
  LAST_ACCOUNT = 'adCoreNG_LastAccount',
  LAST_FEED = 'adCoreNG_LastFeed',
  LAST_FEED_PLUS = 'adCoreNG_LastFeedPlus',
  LAST_ADVERTISER = 'adCoreNG_LastAdvertiser',
  LAST_ROUTE = 'adCoreNG_LastRoute',
  LAST_VERSION = 'adCoreNG_LastVersion',
  LAST_PAGE = 'adCoreNG_LastPage',
  ADCORE_TOKEN = 'adCoreNG_AuthenticationToken',
  AUTO_SAVE_TOKEN = 'adCoreNG_AutoSaveToken',
  LAST_TIME_PERIOD = 'adCoreNG_LastTimePeriod',
  FEEDITOR_WORKING_MODE_TOKEN = 'adCoreNG_feeditorWorkingModeToken',
  FEEDITOR_VERSION_PANEL_MODE_TOKEN = 'adCoreNG_feeditorVersionPanelModeToken',
  COCKPIT_FILTERS_TOKEN = 'adCoreNG_cockpitFiltersToken',
  TOPUP_FILTERS_TOKEN = 'adCoreNG_topUpFiltersToken',
  FEEDITOR_PLUS_IMPORT_INFO_TOKEN = 'adCoreNG_feeditorPlusImportInfoToken'
}

export const localStorageTokenPatch = (lsToken: ELocalStorageTokens, userName: string, attribution?: string, media?: string) => {
  let res = `|${lsToken}|${userName}|`;
  if (attribution) {
    res = `${res}${attribution}|`;
  }
  if (media) {
    res = `${res}${media}|`;
  }
  return res;
};

// must be synced with COMMANDS_FROM_NEW_UI in enums.js old UI
export enum ECommandsFromNewUI  {
  UpdateVersion = 'UpdateVersion',
  CreateVersion = 'CreateVersion',
  UIInProgress = 'UIInProgress',
  RefreshAccountView = 'RefreshAccountView',
  RefreshFeeditorView = 'RefreshFeeditorView',
  ChangeTimePeriod = 'ChangeTimePeriod',
  ViewsSearchStart = 'ViewsSearchStart',
  FeeditorSearchStart = 'FeeditorSearchStart',
  ViewsSearchClear = 'ViewsSearchClear',
  FeeditorSearchClear = 'FeeditorSearchClear',
  ViewsDownloadReport = 'ViewsDownloadReport',
  FeeditorAddColumn = 'FeeditorAddColumn',
  FeeditorWorkingModeChange = 'FeeditorWorkingModeChange',
  ViewsChartsAddOrHideGrid = 'ViewsChartsAddOrHideGrid',
  NgFeeditorCleanup = 'NgFeeditorCleanup',
  NgCollapsibleShowHide = 'NgCollapsibleShowHide',
  NgFeeditorDataChangeClear = 'NgFeeditorDataChangeClear',
  NgViewsMediaConnect = 'NgViewsMediaConnect',
  NgFeeditorFilter = 'NgFeeditorFilter',
  NgFeeditorLabel = 'NgFeeditorLabel',
  NgFeeditorEnrich = 'NgFeeditorEnrich',
  NgFeeditorApplyRecommendations = 'NgFeeditorApplyRecommendations',
  UIFeeditorGridRendered = 'UIFeeditorGridRendered',
  NgViewsRequestForNewVersion = 'NgViewsRequestForNewVersion',
  NgCollapsibleChange = 'NgCollapsibleChange'
}

export enum EFeeditorWorkModes {
  real_time = 'real_time',
  real_time_preview = 'real_time_preview',
  offline = 'offline',
  offline_preview = 'offline_preview'
}

export const shoppingEngineConfig = {
  'export-google-merchant': {key: 'export-google-merchant', id: '64776607a010ac0695a8cc48', channel: 'google'},
  'export-microsoft-merchant': {key: 'export-microsoft-merchant', id: '5863f6f2405ad60f2aa74424', channel: 'bing'},
  'export-ebay-merchant': {key: 'export-ebay-merchant', id: '60f028125d4cd91c27fcc4cc', channel: 'ebay'},
  'export-custom-ftp': {key: 'export-custom-ftp', id: '61b77d144df2f41082e2d1e1', channel: 'custom'},
  'export-criteo-merchant': {key: 'export-criteo-merchant', id: '62ec18b3c809d36b1fe17a49', channel: 'criteo'},
  'export-google-merchant-content-api': {key: 'export-google-merchant-content-api', id: '6398af812a6cae796f1d9b7b', channel: 'google'},
  'export-microsoft-merchant-content-api': {key: 'export-microsoft-merchant-content-api', id: '6398afc52a6cae796f1d9b7d', channel: 'bing'},
  // old id, temporary solution
  'export-google-merchant-old': {key: 'export-google-merchant', id: '5863f6f2405ad60f2aa74426', channel: 'google'}
};

export const FACEBOOK_LINK_KEYS = [
  'export-facebook-catalog', 'export-amazon-merchant', 'export-tiktok-merchant',
  'export-pinterest-merchant', 'export-taboola-merchant', 'export-outbrain-merchant'
];

export const FTP_EXPORT_KEYS = [
  'export-google-merchant', 'export-microsoft-merchant', 'export-criteo-merchant',
  'export-ebay-merchant', 'export-custom-ftp'
];

export const GET_LINK_KEYS = [
  'export-get-link'
];

export const DOWNLOAD_FEED_KEYS = [
  'export-download-feed'
];


export const exportKeyFromId = (_id: string): string | null => {
  const v = Object.values(shoppingEngineConfig).find((s) => s.id === _id);
  return  (v) ? v.key : null;
};


export const tokenKey = (keyType: string | null = 'last', appName: string | null = 'feeditor'): ELocalStorageTokens | null => {
  switch (keyType) {
    case 'last':
      return (appName === 'feeditor') ? ELocalStorageTokens.LAST_FEED : ELocalStorageTokens.LAST_FEED_PLUS;
    case 'recent':
      return (appName === 'feeditor-plus') ? ELocalStorageTokens.RECENT_PLUS_FEEDS : ELocalStorageTokens.RECENT_FEEDS;
  }
  return null;
};
export const demoUserEmails: string[] = [
];


export const SYSTEM_LANGUAGES: any[] = [
  {
    value: 'ENGLISH',
    key: 'en'
  },
  {
    value: 'DEUTSCH',
    key: 'de'
  }, {
    value: 'DUTCH',
    key: 'nl'
  }, {
    value: 'FRENCH',
    key: 'fr'
  },
  {
    value: 'SPANISH',
    key: 'es'
  },
  {
    value: 'RUSSIAN',
    key: 'ru'
  },
  {
    value: 'TRADITIONAL_CHINESE',
    key: 'tc'
  },
  {
    value: 'SIMPLIFIED_CHINESE',
    key: 'sc'
  },
  {
    value: 'VIETNAMESE',
    key: 'vi'
  },
  {
    value: 'ARABIC',
    key: 'ar'
  },
  {
    value: 'PHILIPPINES',
    key: 'tl'
  },
  {
    value: 'UKRAINIAN',
    key: 'uk'
  }
];

export const ITEM_TYPES =  [
  EItemType.ARTICLE,
  EItemType.VIDEO,
  EItemType.CUSTOM,
  EItemType.ALERTER_POLICY_GUIDE,
  EItemType.MEDIA_BLAST_POLICY_GUIDE,
  EItemType.MEDIA_BLAST_ARTICLES,
  EItemType.CHECKLIST_POLICY_GUIDE,
  EItemType.VIEW_COCKPIT
];

export const ASSET_TYPES = [
  'video/mp4',
  'video/ogg',
  'video/webm',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/bmp',
  'webpage'
];

export const PREVIEW_TYPES = [
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/bmp'
];

export const MAX_ITEMS_CONTENT_API_UPLOAD = 50000;

export const MAX_FEEDITOR_PLUS_ACTIVE_RULES = 50;
export const MAX_FEEDITOR_PLUS_TOTAL_RULES = 100;
export const MAX_FEEDITOR_PLUS_ACTIVE_VERSIONS = 5;


export const defaultFeedMediaData = {
  feedUrl: '',
  feedName: '',
  feedHasLongTextColumns: false,
  feedEnforceEncodingFix: false,
  override_entry: '',
  feedIsSecured: false,
  feedUsername: '',
  feedPassword: '',
  feedStatus: 'active'
};

export const MEDIA_BLAST_CHANNELS: any[] = [
  {value: 'google_ads', label: 'GOOGLE_ADS', image: 'logo-google-ads.png'},
  {value: 'microsoft_ads', label: 'MICROSOFT_ADS', image: 'logo-microsoft-ads.svg'},
  {value: 'criteo', label: 'CRITEO', image: 'logo-criteo.png'},
  {value: 'rtbhouse', label: 'RTB_HOUSE', image: 'logo-rtbhouse.svg'},
  {value: 'yandex', label: 'YANDEX', image: 'logo-yandex.png'},
  {value: 'display_360', label: 'DISPLAY_360', image: 'logo-display-360.png'},
  {value: 'xandr', label: 'XANDR', image: 'logo-xandr.svg'}
];
