import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as models from '../../helpers/models';
import * as cfg from '../../helpers/config';
import {AuthenticationService} from '../../helpers/authentication.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaService} from '../../helpers/media.service';
import {ProfileLayoutComponent} from '../profile-layout.component';
import * as utils from '../../helpers/utils';
import {MessageService} from 'primeng/api';
import {timer} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl = '/';
  loading = false;
  isFirstNavigation = false;
  loginInfo: models.ILoginInfo = {username: '', password: '', socialLogin: ''};
  hasError = false;
  hasUserError = false;
  isForgotPassword = false;
  show2fa = false;
  inProcess = false;
  resendTimes = 0;
  countdown = 30;
  isResendDisabled = false;
  verifyInfo: any = null;
  phoneCodeCorrect = false;
  codeError = false;
  loginToken: any = null;

  constructor(private authenticationService: AuthenticationService,
              private mediaService: MediaService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent) {
    this.authenticationService.onSignInSuccess
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response, status: this.authenticationService.authenticationStatus, url: this.returnUrl});
        if (response.user?.upgradedLogin && response.user.mobile) {
          this.loginToken = response.token;
          this.send2faRequest();
        } else {
          if (this.returnUrl) {
            this.parent.winRef.reloadLocation(this.returnUrl.replace('/', ''));
            // this.router.navigateByUrl(this.returnUrl).then(v => {
            //   const urlTree = this.router.parseUrl(this.returnUrl);
            //   urlTree.queryParams = {};
            //   this.authenticationService.lastRoute = urlTree.toString();
            // });
          } else {
            this.authenticationService.authenticate();
          }
        }
      });
    this.authenticationService.onAuthenticationEnd
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response}, 'authentication end on login');
        this.navigateToDefault();
      });
    this.authenticationService.onAuthenticationStart
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log('authentication start on login');
        this.loading = true;
      });
  }
  send2faRequest(): void {
    this.inProcess = true;
    this.parent.apiCallService.apiSend2faVerificationWithMode(this.mediaService?.currentUser)
      .pipe(takeUntil(this.parent.componentDestroyed),
        finalize(() => {
          this.inProcess = false;
        })
      )
      .subscribe((response) => {
        if (!response.error) {
          this.verifyInfo = response;
          const detail = `A code has been sent to your ${response.channel === 'sms' ? 'mobile' : 'email'} ${response.identifier}!`;
          this.messageService.add({
            key: 'reset-link',
            severity: 'success',
            closable: false,
            detail});
          this.resendTimes++;
          this.show2fa = true;
          this.startCountdown(30);
        } else {
          this.messageService.add({
            key: 'reset-link',
            severity: 'error',
            closable: false,
            detail: 'An error occur when sending the verification code!'});
        }
      });
  }

  startCountdown(seconds: number): void {
    this.isResendDisabled = true;
    this.countdown = seconds;
    const countdownTimer = timer(0, 1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.isResendDisabled = false;
        countdownTimer.unsubscribe(); // Stop the timer
      }
    });
  }

  async onApplyingPhoneCode(accessCode: string): Promise<void> {
    this.codeError = false;
    this.phoneCodeCorrect = false;
    this.verifyInfo.code = accessCode;
    if (!this.inProcess) {
      this.inProcess = true;
      this.parent.apiCallService.apiCheck2faVerification(this.verifyInfo)
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          if (response.status === 'approved') {
            this.phoneCodeCorrect = true;
            this.mediaService.accessToken = this.loginToken;
            setTimeout(() => {
              if (this.returnUrl) {
                this.parent.winRef.reloadLocation(this.returnUrl.replace('/', ''));
              } else {
                this.authenticationService.authenticate();
              }
              this.inProcess = false;}, 2000);
          } else {
            this.codeError = true;
            this.inProcess = false;
          }
        });
    }

  }

  get isShowLicenseError(): boolean {
    return Boolean(this.authenticationService.authenticationStatus.err &&
      this.authenticationService.authenticationStatus.errCode === 'user_inactive');
  }

  get failedAttempts(): number {
    return this.authenticationService.authenticationStatus.failedAttempts || 0;
  }

  get isUserEmailNotFound(): boolean {
    return this.authenticationService.authenticationStatus.err === 'user email is not found';
  }

  get isAdvertiserNotActive(): boolean {
    return this.authenticationService.authenticationStatus.errCode === 'advertiser_inactive';
  }

  get isLoginBlocked(): boolean {
    return this.failedAttempts >= cfg.MAX_AMOUNT_OF_LOGIN_ATTEMPTS;
  }

  get maxAttempts(): number {
    return cfg.MAX_AMOUNT_OF_LOGIN_ATTEMPTS;
  }

  get isValidEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return !!this.loginInfo.username && re.test(this.loginInfo.username.toLowerCase());
  }

  sendEmail(): void {
    this.hasError = !this.isValidEmail;
    if (!this.hasError) {
      this.hasUserError = false;
      this.authenticationService.forgotPassword(this.loginInfo.username).subscribe(
        (response) => {
          console.log(response);
          if (response && response.err) {
            this.hasUserError = true;
          } else {
            this.isForgotPassword = false;
            this.messageService.add({
              severity: 'success',
              detail: 'A reset link has been sent to your email!',
              closable: false,
              key: 'reset-link'});
          }
        }
      );
    }
  }

  signIn(): void {
    this.hasError = !this.isValidEmail;
    if (!this.hasError && !this.isLoginBlocked) {
      this.loginInfo.socialLogin = '';
      this.loading = true;
      this.authenticationService.signIn(this.loginInfo);
      this.authenticationService.isBlocked(this.loginInfo.username || '');
      this.parent.winRef.reportGtagEventNew('login', {method: 'Email'});
    }
  }

  createAccount(): void {
    // this.parent.winRef.reloadLocation('join');
    this.router.navigate(['/join']);
  }

  ngOnInit(): void {
    this.loading = this.mediaService.isAuthenticationInProcess;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
    this.isFirstNavigation = this.route.snapshot.data.firstNavigation || false;
    this.parent?.authService.authState.subscribe((user) => {
      if (user) {
        this.loginInfo.socialLogin = user.provider;
        this.loginInfo.username = user.email;
        this.loading = true;
        this.authenticationService.signIn(this.loginInfo);
        this.parent.winRef.reportGtagEventNew('login', {method: utils.capitalize(this.loginInfo.socialLogin)});
      }
    });
  }

  private navigateToDefault(): void {
    if (!this.returnUrl) {
      const modules = this.mediaService.modules;
      // let module: any = this.authenticationService.lastRoute;
      let module = 'my-cloud';
      if (this.mediaService.isFeeditorPlanSelected) {
        module = 'feeditor';
      }
      if (this.mediaService.isFeeditorPlusPlanSelected) {
        module = 'feeditor-plus';
      }
      if (!this.mediaService.isAdminRole && !!modules.mediablast) {
        module = 'media-blast/cockpit';
      }
      if (this.mediaService?.currentUser && !this.mediaService?.currentUser?.upgradedLogin) {
        module = 'upgrade-login';
      }
      // console.log('navigateToDefault  module', module);
      this.router.navigateByUrl(`/${module}`).then(v => {
        this.loading = false;
      });
    } else {
      this.parent.winRef.reloadLocation(this.returnUrl.replace('/', ''));
    }
  }

  isFieldInvalid(): boolean {
    return this.hasError || this.hasUserError || this.isShowLicenseError ||
      !this.isShowLicenseError && this.failedAttempts > 0 && this.failedAttempts < (this.maxAttempts - 1) ||
      !this.isShowLicenseError && this.failedAttempts === (this.maxAttempts - 1) ||
      !this.isShowLicenseError && this.isLoginBlocked;
  }

}
